import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import ContactForm from "../components/contact/contactForm"
import InvestorPopup from "../components/common/investorPopup"

function Contact() {
  const [investPopup, setInvestPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => setInvestPopup(true), 7000)
  }, [])
  return (
    <Layout>
      <SEO
        title="Contact us"
        description="Whether you have a question, want to know more about Manus or just want
to say hello, we’re happy to hear from you."
      />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div className="main-container pt-20 lg:pt-28 bg-gray-200 -mb-20 lg:-mb-24 pb-36">
        <div className="container px-3 md:px-6">
          <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
            <h2 className="text-4xl lg:text-5xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-3 mt-12 ">
              Contact Us
            </h2>
            <p className="text-gray-600 text-sm-base lg:text-base leading-normal font-medium text-center mb-0">
              Whether you have a question, want to know more about Manus or just
              want to say hello, we’re happy to hear from you.
            </p>
          </div>
          <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10">
            <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
            <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
          </div>
          <div className="w-full lg:w-8/12 lg:mx-auto flex flex-col-reverse lg:flex-row mt-8 lg:mt-16">
            {/* <div className="w-full lg:w-4/12 lg:border-r lg:border-gray-300 text-center lg:text-right lg:pr-10 mt-10 lg:mt-0">
                            <p className="text-blue-400 text-2xl font-semibold mb-0">General Enquires</p>
                            <p className="text-gray-300 text-sm-base leading-normal lg:leading-relaxed font-medium  mb-0">For any general enquiries</p>
                            <div className="flex justify-center lg:justify-end">
                                <img src={EmailIcon} className="w-5 mb-0 mr-2" alt="Manus contact" />
                                <p className="text-blue-400 text-xs leading-normal lg:leading-relaxed mb-0">
                                    <Link to="mailo:hello@manusapp.com" />mailo:hello@manusapp.com</p>
                            </div>
                            <p className="text-blue-400 text-2xl font-semibold mb-0 mt-6">Help</p>
                            <p className="text-gray-300 text-sm-base leading-normal lg:leading-relaxed font-medium  mb-0">For support please contact</p>
                            <div className="flex justify-center lg:justify-end">
                                <img src={EmailIcon} className="w-5 mb-0 mr-2" alt="email" />
                                <p className="text-blue-400 text-xs leading-normal lg:leading-relaxed mb-0">
                                    <Link to="mailo:hello@manusapp.com" />mailo:hello@manusapp.com</p>
                            </div>
                        </div> */}
            <ContactForm />
          </div>
        </div>
        {investPopup ? (
          <InvestorPopup setInvestPopup={setInvestPopup} />
        ) : (
          <></>
        )}
      </div>
    </Layout>
  )
}

export default Contact
