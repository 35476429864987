import React , {useState} from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from 'yup'
import Select from 'react-select'
import Recaptcha from 'react-recaptcha'

const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50, 'First name is too long').required('This field is required'),
    surname: Yup.string().max(50, 'Last name is too long').required('This field is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    role: Yup.string().required('This field is required'),
    message: Yup.string().required('This field is required'),
    recaptcha: Yup.string().required('This field is required'),
  });

const roleOptions = [
    { value: 'general_enquiries', label: 'General Enquiries'},
    { value: 'help_and_media_enquiries', label: 'Help and Media Enquiries' },
];

function ContactForm(){
  const [formSent,setFormSent] = useState(''); 
  const [loading,setLoading] = useState(false);

    const onSubmit = (values) => {
      setLoading(true);
        fetch(`https://api.apispreadsheets.com/data/20792/`, {
          method: "POST",
          body: JSON.stringify({
            data: [
              {
                email: values.email,
                phone: values.phone,
                message: values.message,
                last_name: values.surname,
                first_name: values.name,
                who_are_you: values.role,
              },
            ],
          }),
        }).then(res => {
          setLoading(false);
          if (res.status === 201) {
            setFormSent('sent')
          } else {
            setFormSent('failed')
          }
        }).catch((error)=>{
          console.log(error)
        })
      }
    return (
        <div className="w-full">
        <Formik
            initialValues={{
                name: '',
                surname: '',
                phone: '',
                email: '',
                message: '',
                role: '',
                recaptcha: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={values => {
                onSubmit(values)
            }}
        >
            {({ errors, touched, setFieldValue,isSubmitting }) => (
            <Form className="mb-0">
                <div className="w-full">
                    <Field 
                    name="message" 
                    as="textarea" 
                    rows="5" 
                    className="w-full border border-gray-300 rounded-3xl py-5 px-7 outline-none" 
                    placeholder="Your Message... *"/>
                    {errors.message && touched.message && <div className="ml-7 text-xs text-red-500 font-semibold">{errors.message}</div>}
                    <Select
                        options={roleOptions}
                        placeholder="Enquiries *"
                        name="role"
                        className="mt-2 py-2"
                        onChange={(option) => setFieldValue('role', option.value)}
                    />
                    {errors.role && touched.role && <div className="ml-7 text-xs text-red-500 font-semibold">{errors.role}</div>}
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2 lg:mr-2">
                    <Field 
                    id="name" 
                    name="name" 
                    placeholder="First Name *" 
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-6 outline-none" />
                    {errors.name && touched.name && <div className="ml-7 text-xs text-red-500 font-semibold mt-2">{errors.name}</div>}
                    </div>
                    <div className="w-full lg:w-1/2 lg:ml-2">
                    <Field 
                    id="surname" 
                    name="surname" 
                    placeholder="Last Name *" 
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-6 outline-none"/>
                    {errors.surname && touched.surname && <div className="ml-7 text-xs text-red-500 font-semibold mt-2">{errors.surname}</div>}
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2 lg:mr-2">
                    <Field 
                    id="phone" 
                    name="phone" 
                    placeholder="Phone *" 
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-6 outline-none" />
                    {errors.phone && touched.phone && <div className="ml-7 text-xs text-red-500 font-semibold mt-2">{errors.phone}</div>}
                    </div>
                    <div className="w-full lg:w-1/2 lg:ml-2">
                    <Field
                    id="email"
                    name="email"
                    placeholder="Email *"
                    type="email"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-6 outline-none"
                    />
                    {errors.email && touched.email && <div className="ml-7 text-xs text-red-500 font-semibold mt-2">{errors.email}</div>}
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                  <div className="w-full lg:w-1/2 lg:mr-2 mt-6">
                    <Recaptcha
                      sitekey="6LfshLEaAAAAAKfVQmYVX6S1YPmdmgeYYnH3tA1Q"
                      render="explicit"
                      verifyCallback={(response) => {setFieldValue("recaptcha", response)}}
                      onloadCallback={()=>{}}
                    />
                    {errors.recaptcha && touched.recaptcha && <div className="ml-7 text-xs text-red-500 font-semibold mt-2">{errors.recaptcha}</div>}
                  </div>
                  <div className="w-full lg:w-1/2 lg:ml-2">
                    <button 
                      disabled={ loading && isSubmitting ? true : false} 
                      type="submit" 
                      className={`hover:bg-gray-300 text-white text-sm px-12 py-4 rounded-full whitespace-nowrap w-full mt-8 mb-0 focus:outline-none ${loading && isSubmitting ? 'bg-gray-300' : 'bg-blue-400'}`}
                    >    
                      {loading && isSubmitting ? "Sending...": "Send Message"}
                    </button>
                    {formSent === 'sent' ? <div className="text-xs text-blue-400 font-semibold text-left w-full  mt-3">Your email is sent.</div> : formSent === 'failed' ? <div className="text-xs text-red-400 font-semibold text-left w-full md:w-fit-content px-12 mt-3">Your  email is not sent. Please try again or contact us at hello@manusapp.com</div> : <div></div>}
                  </div>
                </div>
            </Form>
            )}
        </Formik>
    </div>
    );
}

export default ContactForm;